import "./App.css";
import {Routes, Route} from "react-router-dom";
import HomePage from "./pages/HomePage.react";
import {BrowserRouter} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar.react";
import AboutPage from "./pages/AboutPage.react";
import ServicesPage from "./pages/ServicesPage.react";
import ProjectsPage from "./pages/ProjectsPage.react";
import Footer from "./components/Footer/Footer.react";
import ContactPage from "./pages/ContactPage.react";
import ServiceDetailsPage from "./pages/ServiceDetailsPage.react";
import {useEffect, useState} from "react";
import {databases} from "./appwrite";

function App() {
  const [contactInfo, setContactInfo] = useState(null);
  const [aboutInfo, setAboutInfo] = useState(null);

  useEffect(() => {
    //fetch contacts from backend

    const getContactInfo = async () => {
      const contacts = await databases.listDocuments(
        process.env.REACT_APP_APPWRITE_DB_ID,
        process.env.REACT_APP_APPWRITE_PAGEINFO_COLLECTION_ID
      );
      const contactInfo = contacts.documents[contacts.total - 1];
      setContactInfo(contactInfo);
    };

    const getAboutInfo = async () => {
      const about = await databases.listDocuments(
        process.env.REACT_APP_APPWRITE_DB_ID,
        process.env.REACT_APP_APPWRITE_ABOUTPAGE_COLLECTION_ID
      );
      const aboutInfo = about.documents[about.total - 1];
      setAboutInfo(aboutInfo);
    };

    getContactInfo();
    getAboutInfo();

    //fetch services from backend

    //fetch projects from backend
  }, []);
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage pageInfo={aboutInfo}/>} />
        <Route path='/services' element={<ServicesPage />} />
        <Route path='/projects' element={<ProjectsPage />} />
        <Route
          path='/contact'
          element={<ContactPage contactInfo={contactInfo} />}
        />
        <Route path='/service-details/*' element={<ServiceDetailsPage />} />
      </Routes>
      <Footer contactInfo={contactInfo} />
    </BrowserRouter>
  );
}

export default App;
