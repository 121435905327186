import React from 'react';
import Hero from '../components/Hero/Hero.react';
const HomePage = () => {
  return (
    <>
      <Hero />
    </>
  );
};

export default HomePage;
