import React from "react";

const FooterInfo = ({contactInfo}) => {
  return (
    <div className='col-lg-4 col-md-6'>
      <div className='footer-info'>
        <h3>Baldauf Construction</h3>
        {contactInfo && (
          <p>
            {contactInfo.address}
            <br />
            <strong>Phone:</strong> {contactInfo.phone}
            <br />
            <strong>Email:</strong> {contactInfo.email}
            <br />
          </p>
        )}
      </div>
    </div>
  );
};

export default FooterInfo;
