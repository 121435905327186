import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs.react';
import ServicesSection from '../components/ServicesSection/ServicesSection.react';

const ServicesPage = () => {
  return (
    <>
      <Breadcrumbs currentPage='Services' />
      <ServicesSection />;
    </>
  );
};

export default ServicesPage;
