// https://appwrite.io/docs/getting-started-for-web
import {Client, Account, ID, Databases, Storage} from "appwrite";

const client = new Client()
  .setEndpoint("https://cloud.appwrite.io/v1")
  .setProject(process.env.REACT_APP_APPWRITE_PROJECT_ID);

const account = new Account(client);
const databases = new Databases(client);
const storage = new Storage(client);

export {client, account, databases, ID, storage};
