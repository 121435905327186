import React from "react";

const FooterCopyright = () => {
  return (
    <div className='footer-legal text-center position-relative'>
      <div className='container'>
        <div className='copyright'>
          &copy; Copyright{" "}
          <strong>
            <span>Baldauf Construction</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className='credits'>
          Built by{" "}
          <a href='https://my-portfolio-dcdekzgnn-crowdedroom.vercel.app/'>
            DJ White
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterCopyright;
