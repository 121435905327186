import React from 'react';
import FooterCopyright from '../FooterCopyright/FooterCopyright.react';
import FooterInfo from '../FooterInfo/FooterInfo.react';

const Footer = ({contactInfo}) => {
  return (
    <footer id='footer' className='footer'>
      <div className='footer-content position-relative'>
        <div className='container'>
          <div className='row'>
            <FooterInfo contactInfo={contactInfo} />

            <div className='col-lg-2 col-md-3 footer-links'>
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li>
                  <a href='/about'>About Us</a>
                </li>
                <li>
                  <a href='/services'>Services</a>
                </li>
                <li>
                  <a href='/projects'>Projects</a>
                </li>
                <li>
                  <a href='/contact'>Contact Us</a>
                </li>
              </ul>
            </div>

            <div className='col-lg-4 col-md-3 footer-links'>
              <h4>Our Services</h4>
              <ul>
                <li>
                  <a href='/service-details/industrial'>Industrial</a>
                </li>
                <li>
                  <a href='/service-details/commercial'>Commercial</a>
                </li>
                <li>
                  <a href='/service-details/residential'>Residential</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterCopyright />
    </footer>
  );
};

export default Footer;
