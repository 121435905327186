import React, {useEffect, useState} from "react";
import ServiceItem from "../ServiceItem/ServiceItem.react";
import {databases} from "../../appwrite";

const ServicesSection = () => {
  const [services, setServices] = useState([]);
  useEffect(() => {
    const getServices = async () => {
      const services = await databases.listDocuments(
        process.env.REACT_APP_APPWRITE_DB_ID,
        process.env.REACT_APP_APPWRITE_SERVICES_COLLECTION_ID
      );
      return services;
    };

    getServices().then((services) => {
      console.log(services.documents[0]);
      setServices(services.documents);
    });
  }, []);

  return (
    <section id='services' className='services section-bg'>
      <div className='container' data-aos='fade-up'>
        <div className='section-header'>
          <h2>Services</h2>
          <p>
            Offering a diverse array of services to cater to various sectors,
            ensuring quality and commitment in every project
          </p>
        </div>

        <div className='row gy-4'>
          {services.map((service) => (
            <ServiceItem
              key={service.$id}
              title={service["service-summary-card-title"]}
              icon={
                <i
                  className={`fa-sharp fa-solid ${service["font-awesome-icon"]}`}
                />
              }
              content={service["service-summary-card-content"]}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;

// <ServiceItem
//   title='Commercial'
//   icon={<i className='fa-sharp fa-solid fa-screwdriver-wrench' />}
//   content='Our commercial services are designed to enhance your business operations, focusing on scalability and reliability.'
// />
// <ServiceItem
//   title='Residential'
//   icon={<i className='fa-sharp fa-solid fa-compass-drafting' />}
//   content='Experience our tailor-made residential services, where comfort meets modern design for an outstanding living experience.'
// />
