import React from "react";

const ServiceItem = ({title, content, icon}) => {
  return (
    <div className='col-lg-4 col-md-6' data-aos='fade-up' data-aos-delay='100'>
      <div className='service-item  position-relative'>
        <div className='icon'>{icon}</div>
        <h3>{title}</h3>
        <p>{content}</p>
        <a
          href={`/service-details/${title.toLowerCase()}`}
          className='readmore stretched-link'
        >
          Learn more <i className='bi bi-arrow-right'></i>
        </a>
      </div>
    </div>
  );
};

export default ServiceItem;
