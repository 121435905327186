import React from "react";
import {Link, NavLink} from "react-router-dom";
const Navbar = () => {
  const [mobileNavActive, setMobileNavActive] = React.useState(false);
  const mobileNavToggle = (event) => {
    const mobileNavShow = document.querySelector(".mobile-nav-show");
    const mobileNavHide = document.querySelector(".mobile-nav-hide");
    const mobileNav = document.querySelector("#mobile-nav-bar");
    document.querySelector("body").classList.toggle("mobile-nav-active");
    mobileNavShow.classList.toggle("d-none");
    mobileNavHide.classList.toggle("d-none");
    mobileNav.classList.toggle("d-block");
    setMobileNavActive(!mobileNavActive);
  };
  return (
    <header id='header' className='header d-flex align-items-center'>
      <div className='container-fluid container-xl d-flex align-items-center justify-content-between'>
        <a href='/' className='logo d-flex align-items-center'>
          <h1>
            Baldauf Construction<span>.</span>
          </h1>
        </a>

        <nav id='navbar' className='navbar'>
          <ul id='mobile-nav-bar'>
            <li>
              <NavLink
                to='/'
                onClick={() => {
                  mobileNavActive && mobileNavToggle();
                }}
                className={({isActive, isPending}) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/about'
                onClick={() => {
                  mobileNavActive && mobileNavToggle();
                }}
                className={({isActive, isPending}) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/services'
                onClick={() => {
                  mobileNavActive && mobileNavToggle();
                }}
                className={({isActive, isPending}) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/projects'
                onClick={() => {
                  mobileNavActive && mobileNavToggle();
                }}
                className={({isActive, isPending}) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/contact'
                onClick={() => {
                  mobileNavActive && mobileNavToggle();
                }}
                className={({isActive, isPending}) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
        <i
          onClick={mobileNavToggle}
          className='mobile-nav-toggle mobile-nav-show bi bi-list '
        ></i>
        <i
          onClick={mobileNavToggle}
          className='mobile-nav-toggle mobile-nav-hide d-none bi bi-x'
        ></i>
      </div>
    </header>
  );
};

export default Navbar;
