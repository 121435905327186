/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs.react";
import {databases} from "../appwrite";
import Isotope from "isotope-layout";

const ProjectsPage = () => {
  // init one ref to store the future isotope object
  const isotope = React.useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = React.useState(null);
  const [projects, setProjects] = React.useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      const projects = await databases.listDocuments(
        process.env.REACT_APP_APPWRITE_DB_ID,
        process.env.REACT_APP_APPWRITE_PROJECTS_COLLECTION_ID
      );
      setProjects(projects.documents);
      console.log(projects.documents);
    };
    fetchProjects();
  }, []);

  // initialize an Isotope object with configs
  React.useEffect(() => {
    if (projects) {
      isotope.current = new Isotope(".portfolio-container", {
        itemSelector: ".portfolio-item",
      });
      
    }
  }, [projects]);

  // handling filter key change
  React.useEffect(() => {
    if (projects) {
      filterKey === "*"
        ? isotope.current.arrange({filter: `*`})
        : isotope.current.arrange({filter: `.${filterKey}`});
    }
  }, [filterKey, projects]);

  const handleFilterKeyChange = (key) => setFilterKey(key);
  return (
    <main id='main'>
      <Breadcrumbs currentPage='Projects' />
      {projects && (
        <section id='projects' className='projects mh-p '>
          <div className='container' data-aos='fade-up'>
            <div
              className='portfolio-isotope'
              data-portfolio-filter='*'
              data-portfolio-layout='masonry'
              data-portfolio-sort='original-order'
            >
              <ul
                className='portfolio-flters'
                data-aos='fade-up'
                data-aos-delay='200'
              >
                <li
                  className={
                    filterKey === "filter-residential"
                      ? "filter-active"
                      : undefined
                  }
                  onClick={() => handleFilterKeyChange("filter-residential")}
                >
                  Residential
                </li>
                <li
                  className={
                    filterKey === "filter-commercial"
                      ? "filter-active"
                      : undefined
                  }
                  onClick={() => handleFilterKeyChange("filter-commercial")}
                >
                  Commercial
                </li>
                <li
                  className={
                    filterKey === "filter-industrial"
                      ? "filter-active"
                      : undefined
                  }
                  onClick={() => handleFilterKeyChange("filter-industrial")}
                >
                  Industrial
                </li>
              </ul>

              <div
                className='row gy-4 portfolio-container'
                data-aos='fade-up'
                data-aos-delay='200'
              >
                {projects.map((project) => {
                  const filterType = project.project_type.toLowerCase();
                  return (
                    <div
                      className={`col-lg-4 col-md-6 portfolio-item filter-${filterType}`}
                      key={project.$id}
                    >
                      <div
                        className='portfolio-content h-100 d-grid'
                      >
                        <img
                          src={project.project_image_url}
                          className='img-fluid'
                          alt='project'
                        />
                        <div className='portfolio-info'>
                          <h4>{project.project_tag}</h4>
                          <p>{project.project_text}</p>
                          <a
                            href={project.project_image_url}
                            title={project.project_tag}
                            data-gallery={`portfolio-gallery-${filterType}`}
                            className='glightbox preview-link'
                          >
                            <i className='bi bi-zoom-in'></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default ProjectsPage;
