import React from "react";

const ContactCard = ({ title, content, icon }) => {
  return (
    <div className="col-lg-4">
      <div className="info-item  d-flex flex-column justify-content-center align-items-center">
        {icon}
        <h3>{title}</h3>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default ContactCard;
