import React from "react";
import ContactCard from "../components/ContactCard/ContactCard.react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs.react";

const ContactPage = ({contactInfo}) => {
  return (
    <>
      <Breadcrumbs currentPage='Contact' />
      <section id='contact' class='contact'>
          <div class='container' data-aos='fade-up' data-aos-delay='100'>
            {contactInfo && (
              <div class='row gy-4'>
                <ContactCard
                  title='Our Address'
                  content={contactInfo.address}
                  icon={<i class='bi bi-map' />}
                />
                <ContactCard
                  title='Email Us'
                  content={
                    <a
                      className='link-dark link-underline-dark'
                      href={`mailto:${contactInfo.email}`}
                    >
                      {contactInfo.email}
                    </a>
                  }
                  icon={<i class='bi bi-envelope' />}
                />
                <ContactCard
                  title='Call Us'
                  content={contactInfo.phone}
                  icon={<i class='bi bi-telephone' />}
                />
              </div>
            )}
            <div class='row gy-4 mt-1'>
              <div class='col-lg-12 d-flex flex-column justify-content-center align-items-center'>
                {contactInfo && (
                  <iframe
                    src={contactInfo.google_maps_link}
                    width='600'
                    height='450'
                    title='Map of Baldauf Construction'
                    style={{border: 0}}
                    allowfullscreen=''
                    loading='lazy'
                    referrerpolicy='no-referrer-when-downgrade'
                  ></iframe>
                )}
              </div>
            </div>
          </div>
      </section>
    </>
  );
};

export default ContactPage;
