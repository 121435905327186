import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs.react';

const AboutPage = ({pageInfo}) => {
  console.log(pageInfo);
  return (
    <>
      <Breadcrumbs currentPage='About' />
      <section id='about' className='about'>
        <div className='container' data-aos='fade-up'>
          <div className='row position-relative'>
            <div
              className='col-lg-7 about-img'
              style={{ backgroundImage: `url(${pageInfo.image})` }} // get link to image from DB
            ></div>

            <div className='col-lg-7'>
              <h2>Baldauf Construction</h2>
              <div className='our-story'>
                <h4>Est 1882</h4>
                <h3>Our Story</h3>
                <p>{pageInfo && pageInfo.story}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutPage;
