import React, {useEffect} from "react";
import {databases} from "../../appwrite";

const Hero = () => {
  const [content, setContent] = React.useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const doc = await databases.listDocuments(
        process.env.REACT_APP_APPWRITE_DB_ID,
        process.env.REACT_APP_APPWRITE_HOMEPAGE_COLLECTION_ID
      );
      const text = doc.documents[doc.documents.length - 1].content;
      setContent(text);
    }; // const doc = databases
    fetchData();
  }, []);

  return (
    <section id='hero' className='hero'>
      <div className='info d-flex align-items-center'>
        <div className='container'>
          <div className='row justify-content-center'>
            {content && (
              <div className='col-lg-6 text-center'>
                <div className='main-head-title'>
                  <h2 className='' data-aos='fade-down'>
                    Welcome to <b>Baldauf Construction</b>
                  </h2>
                </div>
                <p className='' data-aos='fade-up'>
                  {content}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        id='hero-carousel'
        className='carousel slide'
        data-bs-ride='carousel'
        data-bs-interval='5000'
      >
        <div
          className='carousel-item active'
          style={{
            backgroundImage: `url(../../../img/hero-carousel/hero-carousel-1.jpg)`,
          }}
        ></div>
        <div
          className='carousel-item'
          style={{
            backgroundImage:
              "url(../../../img/hero-carousel/hero-carousel-2.jpg)",
          }}
        ></div>
        <div
          className='carousel-item'
          style={{
            backgroundImage:
              "url(../../../img/hero-carousel/hero-carousel-3.jpg)",
          }}
        ></div>
        <div
          className='carousel-item'
          style={{
            backgroundImage:
              "url(../../../img/hero-carousel/hero-carousel-4.jpg)",
          }}
        ></div>
        <div
          className='carousel-item'
          style={{
            backgroundImage:
              "url(../../../img/hero-carousel/hero-carousel-5.jpg)",
          }}
        ></div>

        <a
          className='carousel-control-prev'
          href='#hero-carousel'
          role='button'
          data-bs-slide='prev'
        >
          <span
            className='carousel-control-prev-icon bi bi-chevron-left'
            aria-hidden='true'
          ></span>
        </a>

        <a
          className='carousel-control-next'
          href='#hero-carousel'
          role='button'
          data-bs-slide='next'
        >
          <span
            className='carousel-control-next-icon bi bi-chevron-right'
            aria-hidden='true'
          ></span>
        </a>
      </div>
    </section>
  );
};

export default Hero;
