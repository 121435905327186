import React from "react";
import {Link} from "react-router-dom";

const Breadcrumbs = ({prevPage, currentPage}) => {
  return (
    <div
      className='breadcrumbs d-flex align-items-center'
      style={{backgroundImage: "url(../../../img/breadcrumbs-bg.jpg)"}}
    >
      <div
        className='container position-relative d-flex flex-column align-items-center'
        data-aos='fade'
      >
        <h2>{currentPage}</h2>
        <ol>
          <li>
            <Link to='/'>Home</Link>
          </li>
          {prevPage && (
            <li>
              <Link to={`/${prevPage}`}> {prevPage}</Link>
            </li>
          )}
          <li>{currentPage}</li>
        </ol>
      </div>
    </div>
  );
};

export default Breadcrumbs;
