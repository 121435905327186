"use client";
import {useLocation} from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs.react";
import {useEffect, useState} from "react";
import {databases} from "../appwrite";

const ServiceDetailsPage = (props) => {
  const {pathname} = useLocation();
  const [services, setServices] = useState([]);
  const [currentService, setCurrentService] = useState(null);
  const currentPage = pathname.split("/")[pathname.split("/").length - 1];
  // useState here to store the service details info from the backend

  // add useEffect here to fetch the appropriate service details from the backends

  useEffect(() => {
    const getServiceDetails = async () => {
      const services = await databases.listDocuments(
        process.env.REACT_APP_APPWRITE_DB_ID,
        process.env.REACT_APP_APPWRITE_SERVICES_COLLECTION_ID
      );
      return services;
    };

    getServiceDetails().then((services) => {
      console.log(services.documents[0]);
      setServices(services.documents);
      setCurrentService(
        services.documents.filter(
          (service) =>
            service["service-summary-card-title"].toLowerCase() === currentPage
        )[0]
      );
    });
  }, [currentPage]);
  console.log(currentService);
  return (
    <>
      <Breadcrumbs
        prevPage='Services'
        currentPage={`${currentPage[0].toUpperCase()}${currentPage.slice(1)}`}
      />
      {currentService && (
        <section id='service-details' className='service-details'>
          <div className='container' data-aos='fade-up' data-aos-delay='100'>
            <div className='row gy-4'>
              <div className='col-lg-4'>
                <div className='services-list'>
                  {services.map((service) => (
                    <a
                      key={service.$id}
                      href={`/service-details/${service[
                        "service-summary-card-title"
                      ].toLowerCase()}`}
                      className={`${
                        currentPage ===
                          service["service-summary-card-title"].toLowerCase() &&
                        "active"
                      }`}
                    >
                      {service["service-summary-card-title"]}
                    </a>
                  ))}
                </div>

                <h4>{currentService["service-detail-side-title"]}</h4>
                <p>{currentService["service-detail-side-content"]}</p>
              </div>
              <div className='col-lg-8'>
                <img
                  src='../../../img/services.jpg'
                  alt=''
                  className='services-img img-fluid'
                />
                <h3>{currentService["service-detail-main-title"]}</h3>
                <p>{currentService["service-detail-header-content"]}</p>
                <ul>
                  {currentService["service-detail-bullets"].map((item, idx) => (
                    <li key={idx}>
                      <i className='bi bi-check-circle'></i> <span>{item}</span>
                    </li>
                  ))}
                </ul>
                <p>{currentService["service-detail-footer-content"]}</p>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default ServiceDetailsPage;
